<template>
  <div v-if="policyIndex === 0">
    <div class="div_title">
      <h1>주문</h1>
      <policies/>
    </div>
    <div class="card">
      <p>판매자에게 결제를 하기 전에 소비자님께서 주문 취소를 하는 경우에는 전액 환불해 드립니다.</p>
      <p>판매자에게 결제가 완료된 이후에 소비자님께서 주문 취소를 하는 경우에는 판매자가 취소를 거부하면 취소가 불가하고, 판매자가 취소를 하면 대행 수수료와 외환이체 수수료를 참가한 금액을 환불해 드립니다. </p>
      <p>주문이 취소가 되었거나 오랜 시간 진행이 되지 않으면 문의 게시판에 계좌번호를 남겨주시면 환불해 드립니다.</p>
      <p>구매대행 가능 여부와 견적은 소비자님께서 홈페이지에 대행 신청을 해주시고, 견적을 받아 결제를 해주신 이후에 확인해 드립니다. 구매 불가시 전액 환불해드립니다</p>
      <p>빈티드(VINTED)는 프랑스/스페인/이탈리아/네덜란드/영국/벨기에만 대행이 가능합니다.</p>
      <p>캐로셀(CAROUSELL)은 홍콩과 싱가포르만 대행이 가능합니다.</p>
      <p>shopee(쇼피)는 싱가포르만 대행이 가능합니다.</p>
      <p>판매자에게 결제를 한 이후에 소비자 님의 요청으로 취소/환불이 된 이후에 발생하는 모든 비용은 소비자님께서 지불하셔야 됩니다.</p>
      <p>판매자에게 결제를 완료한 이후라고 해도 판매자가 일방적으로 주문을 취소한 경우에는 전액 환불해 드립니다.</p>
      <p>대행을 원하시면 가장 먼저 geek9.kr에 대행 신청을 해주시면 되고, 요청사항은 geek9.kr에 대행 신청을 하실 때 요청사항에 써주시면 됩니다.</p>
      <p>구매대행은 구매를 원하시는 해외의 상품을 GEEK9에서 직접 구매를 해서 한국으로 보내는 드리는 것을 말합니다.</p>
      <p>구매대행을 요청해 주실 때는 구매를 원하시는 상품의 정확한 링크와 구매 수량을 알려주셔야 됩니다. 이때 주의하실 점은 알려주신 상품 링크로 접속했을 때 구매를 원하시는 상품의 정보(가격/옵션/주문버튼)가 나와야 한다는 점입니다.</p>
      <p>대행신청 신청을 하실 때 올바른 링크를 입력하셔야 빠르게 구매대행 가능 여부와 정확한 견적을 받으실 수 있습니다.</p>
      <p>받는 분의 정보는 한글로 작성해 주시면 됩니다.</p>
      <p>GEEK9가 해외에서 직접 구매까지 해서 한국으로 상품을 보내드리는 구매대행과는 달리 배송대행은 소비자 님께서 직접 해외에서 구매를 하셔서 해당 국가의 GEEK9 주소지로 물건을 보내주셔야 됩니다.</p>
      <p>소비자 님께서 직접 물건을 구매하셔서 GEEK9의 해외 주소지로 물건을 보내주시는 배송대행에 경우에는 별도의 수수료는 없고, 한국으로 보내는 배송비만 발생합니다.</p>
      <p>배송대행이 가능한 국가는 홍콩,프랑스,영국,독일,스페인,이탈리아,일본,중국,미국,싱가포르,호주,네덜란드,오스트리아,뉴질랜드,스웨덴,베트남,마카오입니다. 주소가 변동되거나 일시적으로 배송대행 업무를 하지 않는 경우가 있으니 GEEK9에 꼭 문의하신 후에 대행 신청을 해주시기 바랍니다.</p>
      <p>대행 신청을 하실 때 대행국가는 소비자 님께서 구매를 원하는 상품이 판매되는 국가를 의미합니다.</p>
      <p>관세청에서 통관부호를 발급받기 위해 사용하신 성함과 폰번호를 정확하게 알려주셔야 됩니다. 잘못된 정보를 알려주시면 한국 세관에서 문제가 될 수 있습니다.</p>
      <p>대행 신청을 하실 때 입력하시는 이메일로 비용/진행상황 안내해드리니 이메일 정보를 정확하게 입력해 주시기 바랍니다.</p>
      <p>빈티드/VINTED/카로셀/카루셀/CAROUSELL은 https://geek9.kr에서 실제 대행비를 미리 조회하실 수 있습니다. 대행신청을 해주셔도 견적을 알려 드립니다.</p>
      <p>해외직구를 하기 위해서 구매대행이나 배송대행을 맡기시는 경우에는 대행 수수료, 배송비 외에도 한국 세관에서 세금이 발생할 수도 있습니다.</p>
      <p>대행 진행 가능 여부는 사이트/판매자에 따라 다르기 때문에 소비자 님께서 대행 신청을 해주셔야 판단할 수 있습니다.</p>
    </div>
  </div>
  <div v-else-if="policyIndex === 1">
    <div class="div_title">
      <h1>비용</h1>
      <policies/>
    </div>
    <div class="card">
      <p>해외 결제 금액의 10%가 대행 수수료이고, 예외적으로 싱가포르만 수수료가 20%입니다.</p>
      <p>대행 수수료는 최소 8,000원 입니다.</p>
      <p>합배송한 상품들의 총 판매 가격이 미국은 200달러, 그외 국가는 150달러를 넘으면 한국 세관에서 세금이 발생합니다.</p>
      <p>세금은 소비자님께서 지불하셔야 되는 금액입니다.</p>
      <p>소비자님께서 구매대행 시 배송비를 두 번 지불하는 이유는 해외 현지에서의 배송비와 한국으로 보내는 배송비가 밸상하기 때문입니다.</p>
      <p>GEEK9 홈페이지에서 조회되는 견적에는 대행 수수료와 해외 현지 배송비가 포함된 금액이고, 한국으로 보내는 배송비는 포함되지 않은 금액입니다.</p>
      <p>한국으로 보내는 배송비는 GEEK9가 판매자로부터 물건을 받아 포장을 완료한 이후에 소비자님께 알려 드립니다.</p>
      <p>한국으로 보내는 배송비는 GEEK9가 판매자로부터 물건을 받은 이후에 알 수 있어서 미리 알려드릴 수 없습니다.</p>
      <p>한국으로 보내는 배송비를 미리 알고 싶으시다면 geek9.kr에서 예상 배송비를 조회하시면 됩니다.</p>
      <p>소비자 님께서 예상하신 금액보다 견적이 더 비싼 이유는 실제로 해외 사이트에서 결제 버튼을 누르고 결제 페이지에 가면 현지 배송비, 해외 사이트 수수료, 착불비 등의 비용이 추가되기 때문입니다.</p>
      <p>소비자 님께서 해외 사이트에서 보시는 판매 금액과 실제 결제되는 금액은 차이가 날 수 있습니다.</p>
      <p>네이버에서 "관부가세계산기"를 검색하시면 관부가세를 조회하실 수 있습니다.</p>
      <p>모든 비용은 선결제입니다.</p>
      <p>한국으로 보내드릴 때 합배송 / 분리배송 가능하고, 배송 한 건에 포장된 모든 상품의 가격이 미국 달러 기준으로 150달러(배송 대행지가 미국인 경우에는 200달러) 이상인 경우에는 한국 세관에서 관세와 부가세가 발생하기 때문에 이런 경우에는 세금이 나오지 않도록 분리 배송도 가능합니다.</p>
      <p>추가 포장이나 내용물의 사진촬영을 요청하시는 경우에는 추가 비용이 발생합니다.</p>
      <p>결제대행은 소비자 님께서 알려주시는 해외 계좌나 결제를 GEEK9에서 대신 처리해주는 업무입니다.</p>
      <p>결제대행의 기본 수수료는 8,000원이고, 이체 금액이 원화로 15만 원 이상인 경우에는 기본 수수료 8,000원에 15만 원 당 3,000원이 수수료로 추가됩니다.</p>
      <p>소비자 님께서 결제대행을 신청해주시면 신청한 시점에 바로 결제가 되지 않습니다. 빠르면 몇 시간, 늦어도 2일 이내에 결제가 완료됩니다.</p>
      <p>이체 완료 이후에 결제대행 취소를 하시는 경우에는 판매자가 환불에 동의를 해주어야 취소가 가능하고, 판매자가 환불을 해주면 수수료를 차감한 액수만 환불해 드리며, 환불 시점의 환율에 따라 금액에 변동이 있을 수 있습니다.</p>
      <p>결제대행이 완료되면 이체 내역서를 캡처해서 카톡으로 보내드리니 연락처나 이메일을 정확하게 입력해 주시기 바랍니다.</p>
      <p>개인이 아닌 기업(업체)의 수출/수입/통관/보관/배송 업무에 대한 비용은 별도로 존재합니다. 물량이 많은 경우에는 geek9geek9@gmail.com로 문의해 주시기 바랍니다.</p>
      <p>GEEK9에서 조회되는 견적은 수수료까지 포함된 금액이고, 해외에서 한국으로 보내는 배송비는 별도로 추가됩니다.</p>
      <p>세금은 세관에서 발생하는 비용이기 때문에 GEEK9에서 알려드리는 견적에는 추가되지 않은 비용입니다.</p>
      <p>정확한 관세와 부가세는 한국 세관에서 결정 되지만 네이버에서 '관부가세 계산기'로 검색하시면 대략적인 관세와 부가세를 조회할 수 있습니다.</p>
      <p>소비자 님께서 대행을 맡기시는 품목에 따라서 무조건 세금이 발생하는 경우도 있으니 대행을 맡기시기 전에 해당 내용에 대해서 꼭 알아 보시기 바랍니다.</p>
      <p>한국으로 보내는 비용은 한국으로 보내기 전, 포장이 완료된 상태 기준의 무게와 부피에 따라 결정되기 때문에 GEEK9의 해외 현지 사무실에서 물건을 받기 전에는 정확한 배송 비용을 알려드릴 수 없습니다. 그렇기 때문에 배송 비용은 GEEK9에서 운영하는 해외 현지 창고에 물건이 도착한 이후에 별도로 결제 요청을 드립니다.</p>
      <p>수수료/견적에는 물건구입 + 대행수수료 + 해외현지배송비가 포함된 금액이고, 한국으로 보내는 배송비는 별도입니다.</p>
      <p>모든 비용은 원화로 결제 가능합니다.</p>
      <p>해외 현지 통화로 결제를 원하시는 경우에는 GEEK9에 문의를 해주시기 바랍니다.</p>
      <p>한국으로 보내는 배송비는 단순히 무게 기준으로 결정이 되는 게 아니고, 부피까지 고려해서 책정이 됩니다.</p>
      <p>구매한 물건의 가격/대행비와 배송비는 아무 관계가 없습니다.</p>
    </div>
  </div>
  <div v-else-if="policyIndex === 2">
    <div class="div_title">
      <h1>배송</h1>
      <policies/>
    </div>
    <div class="card">
      <p>홍콩 창고로 입고되는 배송의 착불비가 100hkd가 넘는 경우에는 착불비의 10%가 수수료로 발생합니다.</p>
      <p>해외 창고로 도착하는 배송의 착불비가 발생하는 경우에는 대행신청을 하기 전에 미리 GEEK9에 알려주셔야 됩니다.</p>
      <p>대행을 맡기시는 물건의 무게가 30kg을 초과하거나 가로,세로,높이 중에 하나라도 150cm가 넘는 경우에는 배송이 물가할 수도 있으므로 대행을 맡기시기 전에 미리 GEEK9에 알려주셔야 됩니다.</p>
      <p>GEEK9에서 착불비를 대신 지불하는 경우에는 소비자님께 착불비에 수수료가 추가된 금액을 청구합니다.</p>
      <p>합배송을 하는 방법은 최초에 대행 신청을 하실 때 합배송 할 상품을 동일한 한 건의 주문으로 대행신청을 하시거나 배송비가 나오기 전에 합배송을 원하시는 주문 건들의 IDX를 게시판에 알려주시면 합배송으로 변경이 가능합니다.</p>
      <p>동일한 나라의 대행 주문에 대해서만 합배송이 가능합니다.</p>
      <p>배송비가 나온 이후에는 이미 출고 대기 중인 상태라서 어떠한 변경/취소/환불이 안 됩니다.</p>
      <p>한국으로 보내는 배송비는 구매하신 물건을 포장한 박스의 무게와 부피에 따라 결정되기 때문에 geek9에서 물건을 받은 이후에 소비자님에게 알려드릴 수 있습니다</p>
      <p>해외 판매자가 얼마나 빨리 물건을 보내주느냐에 따라 배송 기간이 결정이 되기 때문에 소비자 님께서 한국에서 물건을 받기까지의 기간은 GEEK9에서도 정확하게 알려드릴 수 없습니다.</p>
      <p>하지만 해외 판매자로부터 GEEK9가 물건을 받은 시점을 기준으로는 홍콩은 3~5일, 그외 국가는 최소 2주일 이상의 시간이 걸립니다. 평일 기준입니다.</p>
      <p>해외 현지 내에서의 배송과 세관 통관 일정은 GEEK9에서 관여할 수 없기 때문에 소비자 님께서 물건을 받으시는 정확한 시점은 GEEK9에서도 알 수 없습니다.</p>
      <p>한국으로 보내는 배송비는 최종적으로 포장이 완료된 택배의 무게와 부피에 따라 결정되기 때문에 해외에서 구매한 물건을 GEEK9에서 받은 후에 소비자님께 알려드릴 수 있습니다. 당연히 배송비도 그때 따로 결제를 해주시면 되고, 해당 배송비를 소비자 님께서 결제해 주시면 한국으로 배송을 보내드립니다.</p>
      <p>한국이 아닌 다른 국가로 배송을 보내시고 싶은 경우에는 일단 한글 주소로 대행 신청을 하신 이후에 문의 게시판에 외국 주소와 함께 배송 가능한지 여부를 먼저 GEEK9에 확인해 주시기 바랍니다.</p>
      <p>한국이 아닌 다른 국가로 배송을 보내시는 경우에는 대행 신청 전에 먼저 GEEK9에 문의를 해주시기 바랍니다.</p>
      <p>개인 주문인 경우에는 가로,세로,높이 중에 하나라도 150cm가 넘으면 배송이 불가할 수 있으므로 대행 신청하시기 전에 반드시 GEEK9에 문의해주세요</p>
      <p>소비자 님께서 배송비를 결제해 주시면 항공이나 선박(나라에 따라 다름)으로 한국으로 이동한 후, 한국에서는 우체국택배나 CJ대한통운으로 구매자 님께 배송이 됩니다.</p>
      <p>주문량이 많지 않은 경우에는 홍콩이나 싱가포르 GEEK9 주소지로 오는 물건에 대해서만 한국이 아닌 다른 국가로의 배송이 가능합니다.</p>
      <p>대행 신청하신 해외 상품을 한국에서 받기까지 걸리는 시간은 "판매처로부터 GEEK9가 물건을 받아서 한국으로 보낸 시점"에서 "영업일" 기준으로 홍콩은 3~5일, 그 외 국가는 "최소" 2주의 시간이 걸립니다. </p>
      <p>해외 상품을 구매하게 되면 판매자가 GEEK9 배송 대행지 주소로 물건을 보내줘야 GEEK9에서 한국의 구매자 님께 해당 상품을 보내드릴 수가 있습니다. GEEK9에서 판매자로부터 물건을 받을 때까지 걸리는 기간은 나라마다 다르기도 하고, 판매자가 판매 상품을 얼마나 빨리 보내주느냐에 따라 결정되기 때문에 주문한 물건을 GEEK9에서 받을 때까지 얼마나 걸릴지는 알 수가 없습니다. </p>
      <p>주말이나 한국 연휴, 해외 연휴, 그 외 기타 업무가 불가능한 기간 등은 전체 배송 기간에 포함되지 않습니다.</p>
      <p>한국 세관에서 잘못된 수취인 정보나 세관 자체의 작업 지연으로 인해 배송 기간이 좀 더 걸릴 수도 있습니다.</p>
      <p>한국 세관을 통과한 물건은 우체국택배나 CJ대한통운을 통해 소비자 분들에게 배송이 됩니다.</p>
    </div>
  </div>
  <div v-else-if="policyIndex === 3">
    <div class="div_title">
      <h1>분쟁</h1>
      <policies/>
    </div>
    <div class="card">
      <p>상품의 하자나 잘못된 상품의 배송, 배송 중의 발생하는 파손이나 분실 등에 대해서는 GEEK9에서 책임지지 않습니다.</p>
      <p>소비자 님께서 대행을 요청하신 해외 판매자가 사기를 치거나 하자가 있는 상품을 보내면 이는 GEEK9에서 책임지지 않습니다.</p>
      <p>GEEK9는 소비자 님께서 요청하신 작업을 대신 처리해주는 일만 하기 때문에 그 과정에서 발생하는 모든 문제에 대해서는 GEEK9에서 책임지지 않습니다.</p>
      <p>해외 판매자의 연락 두절, 구매한 사이트가 사기 사이트인 경우에는 GEEK9에서 책임을 지지 않습니다.</p>
      <p>GEEK9는 물품을 판매하는 것이 아니고, 구매자님께서 대행을 요청한 사이트나 판매자에게 구매자님 대신 문의/응대/구매를 한 후 한국으로 배송을 보내는 물류 업체에 구매한 물건을 맡기는 작업만을 하기 때문에 애초에 구매자님께서 요청하신 판매자/판매사이트가 사기인 경우에는 어떠한 보상도 해드릴 수가 없습니다. 구매처와 구매 여부는 전적으로 구매자님의 결정에 의한 것이고, GEEK9에서는 그 결정을 대신 진행만 하는 거라서 사기 거래 여부를 판단할 수가 없습니다.</p>
      <p>사기거래가 발생한 경우 구매자님께서 요청하신 작업에 대해서는 가능한 일이라면 최대한 도움을 드리고 있습니다. 그러나 현실적으로 해외 판매자가 마음먹고 사기를 친다면 해외직구를 통해 구매하는 쪽에서 할 수 있는 일은 많지 않습니다. 신중하게 해외직구 대행 여부를 결정하시기 바랍니다.</p>
      <p>신중한 대행 결정을 부탁드리겠습니다.</p>
      <p>GEEK9 측 실수나 착오가 아닌 판매자/소비자 측의 과실, 단순 변심으로 인한 교환/환불/취소에 대해서는 GEEK9에서는 어떠한 보상이나 책임도 지지 않습니다. 하지만 교환/환불/취소 과정 중 GEEK9에서 지원이 가능한 일에 대해서는 도움을 드리고 있습니다. 단, 그 과정에서 발생하는 비용은 소비자 님께서 부담하셔야 됩니다.</p>
      <p>교환/환불/취소가 정상적으로 완료가 되면 대행 수수료와 해외 이체 수수료, 카드 수수료 등은 차감해서 환불해 드립니다. 물건이 한국에 도착한 이후에 교환/환불/취소를 원하시는 경우에는 판매자로부터 교환/환불/취소가 가능한다는 답변이 필요하며, 가능하다는 답변을 받으면 한국에서 받으신 물건을 GEEK9 해외 주소지로 직접 보내주셔야 되며, 판매자한테 물건을 보내는 반송비가 발생합니다.</p>
      <p>GEEK9에 구매대행을 맡기신 경우에는 교환/환불/취소 요청을 판매자 측에 대신해드리지만 소비자 측에서 직접 결제한 후 GEEK9에 배송대행만 맡기신 경우에는 소비자가 직접 판매자 측에 교환/환불/취소 요청을 하신 후 관련 정보를 GEEK9에 알려 주셔야 됩니다.</p>
      <p>상품의 하자, 배송 중 파손/분실 등은 GEEK9의 문제가 아닌 판매자나 배송사 측 과실이므로 이와 관련된 문제는 GEEK9에서 책임지지 않습니다.</p>
      <p>환불신청은 문의게시판에 환불 받으실 계좌정보를 남겨주시면 됩니다.</p>
      <p>해외 구매 가격이 150달러(미국은 200달러) 이상인 경우에는 한국 세관에서 관부가세가 발생하는 것처럼 확인이 쉬운 건 기본이고, 그 외에 해외에서 구매하려는 상품의 품목, 수량 등으로 인해 세관에서 문제가 발생할 수 있는지를 알아 본 후에 해외직구 여부를 결정해야 됩니다. 품목 별로 기준이 다 드리기 때문에 세관이나 사용하는 배대지에 문의를 해보는 게 가장 좋지만 100% 정확하지 않으며, 구매 여부, 수량, 옵션 등의 상품 정보는 최종적으로 소비자 쪽에서 결정하는 것이기 때문에 세관에서 발생하는 모든 문제에 대해서 GEEK9는 어떠한 책임도 지지 않습니다.</p>
    </div>
  </div>
  <div v-else-if="policyIndex === 4">
    <div class="div_title">
      <h1>기타</h1>
      <policies/>
    </div>
    <div class="card">
      <p>모든 문의는 https://geek9.kr을 통해서만 가능합니다. 카카오톡과 이메일로는 답변 드리지 않습니다.</p>
      <p>GEEK9에서는 원산지증명/FTA통관 관련 작업을 진행하지 않습니다.</p>
      <p>상품에 배터리가 있는 품목은 세관 통관 시 문제가 될 수 있으니 대행을 맡기시기 전에 GEEK9에 먼저 문의해 주시기 바랍니다.</p>
      <p>해외 상품 구매에서부터 한국으로 배송이 도착할 때까지의 진행 상황은 대행 신청을 할 때 알려주신 이메일로 안내해 드리고 있습니다. 해당 메일을 못 받으신 경우에는 스팸으로 분류가 되어 있거나 잘못된 메일 주소를 알려주신 경우, 또는 진행된 상황이 없기 때문입니다.</p>
      <p>주문접수 : 소비자 님의 주문이 확인되었고, 해외 판매자에게 구매요청해놓은 상태입니다.</p>
      <p>입금대기 : 소비자 님께서 GEEK9에 결제를 해주시면 해외 판매자에게 물건을 구매할 수 있는 상태입니다.</p>
      <p>입금완료 : 소비자 님의 결제 내역이 확인된 상태입니다. 곧 해외 판매자에게서 상품을 구매합니다.</p>
      <p>구매완료 : 해외 판매자에게서 상품 구매가 완료된 상태입니다. 해외 판매자가 상품을 보낼 때까지 기다려 주시기 바랍니다.</p>
      <p>입고대기 : 해외 판매자가 상품을 배송 보냈지만 아직 해당 상품이 GEEK9 해외 창고에 도착하기 전 상태입니다.</p>
      <p>창고도착 : 판매자가 보낸 상품이 GEEK9 창고에 도착한 상태입니다. 한국으로 보내는 배송비 안내를 곧 받으실 수 있습니다.</p>
      <p>배송중 : 상품이 한국으로 배송 중입니다. 한국 세관을 통과해야 송장번호로 조회가 됩니다.</p>
      <p>거래완료 : 구매자 님께서 상품을 정상적으로 수령한 상태입니다.</p>
      <p>거래취소 : 해외 판매자 혹은 구매자 님의 요청으로 거래가 취소된 상태입니다. 해외 결제라서 환불까지 최소 한 달의 시간이 필요합니다.</p>
      <p>환불완료 : 구매자 님의 계좌로 환불이 완료된 상태입니다.</p>
      <p>해외직구를 하면 무조건 세관 통관 과정을 거쳐야 합니다. 그리고 처음 해외직구를 했을 때 결제/배송과 함께 문제 일어날 여지가 많은 과정이 바로 세관 통관 과정입니다. 품목, 수량, 무게, 가격에 따라 세관에서 세금이나 과태료와 같은 추가적인 비용이 발생할 수도 있고, 세관 쪽에 소명을 하거나 최악의 경우 세관 통과가 되지 않아서 물건을 받지 못할 수도 있습니다.</p>
      <p>해외 판매자에게 사진촬영을 요청하시려면 먼저 geek9.kr에서 대행신청을 하시고, 결제를 해주신 이후에 가능합니다.</p>
    </div>
  </div>
  <div v-else-if="policyIndex === 400">
    <div class="div_title">
      <h1>서비스 이용약관</h1>
      <policies/>
    </div>
    <div>
      <textarea style="width:100%;height:1000px;">
해외구매(배송대행) 표준약관

제1조(목적) 이 약관은 GEEK9(이하 “회사”)가 제공하는 배송관련 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자 간의 권리·의무, 책임사항 및 절차 등을 규정함을 목적으로 합니다. 

제2조(정의) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

1. “몰”은 회사가 이 약관에 의하여 서비스를 이용자에게 제공하기 위하여 정보통신설비와 정보통신망을 이용하여 서비스 등을 거래할 수 있도록 설정한 가상의 영업장을 의미하거나 “몰”을 운영하는 사업자를 의미합니다.

2. “이용자”라 함은 회사가 제공하는 서비스를 이용하는 자를 의미합니다.

3. “해외사업자”라 함은 대한민국 이외의 국적이거나 대한민국 이외의 국가에 사업자 등록, 법인, 영업소, 호스트서버 소재지 등을 가진 사업자를 의미합니다.

4. “배송대행”이라 함은 이용자가 해외사업자로부터 구매한 재화 등(이하 “운송물”)이 회사가 이용자에게 제공하는 고유의 배송대행지에 입고되면 회사가 국제 운송 및 수입통관을 거쳐 해당 운송물을 이용자가 지정한 국내 수령 장소까지 운송하는 서비스를 의미합니다.

5. “배송대행지”라 함은 이용자가 해외에서 운송물을 구매하고자 할 경우 현지의 운송물 수령 장소로 이용하는 회사 제공의 해외 주소지를 의미합니다.

6. “배송대행신청서”라 함은 배송대행 계약의 체결을 위하여 이용자가 운송물 및 국내 수령 장소 관련 정보 등을 기입하여 회사에 송신하는 문서를 의미합니다.

7. “검수”라 함은 이용자가 배송대행을 의뢰한 운송물의 누락, 하자, 파손 여부 등을 회사가 정한 기준에 따라 확인하는 서비스를 의미합니다.


제3조(서비스의 제공) 회사는 다음과 같은 업무를 수행할 수 있습니다.

1. 서비스 등에 대한 정보제공

2. 이용자가 해외에서 구매한 운송물의 수령, 보관, 검수, 인도

3. 이용자가 해외에서 구매한 운송물에 대한 운송계약의 체결

4. 수입 및 통관 관련 업무

5. 반품, 교환, 환불 등 국제반송 관련 업무

6. 기타 회사가 정하는 업무


제4조(서비스 이용 제한)

① 회사는 이용자의 서비스 이용 요청이 다음 각 호의 어느 하나에 해당하는 경우 서비스 제공을 거절하거나, 이용자의 동의를 구하고(다만, 이용자에게 동의를 구할 수 없는 사정이 있는 경우에는 통지 후) 해당 운송물을 폐기하거나 이용자의 비용 부담으로 해외사업자에게 반송할 수 있습니다.

1. 신청내용에 허위, 기재누락, 오기가 있는 경우

2. 이용자가 요청한 서비스의 제공이 회사의 경영상 또는 기술상의 이유로 현저히 곤란한 경우

3. 이용자가 불법 또는 부당한 목적을 위해 서비스를 이용하는 것으로 판단되는 경우

4. 운송물이 동물, 금·은괴, 화폐, 의약품, 무기류, 인체의 일부, 포르노그래피, 정밀금속, 석재류, 냉장보관물품, 냉동 또는 냉장을 요하는 물품 및 폭발물, 가연성 위험물 등인 경우

5. 운송물이 통과국을 포함한 수출입국의 법령에 의하여 수출입이 금지되는 물품인 경우

6. 운송물이 운송 사업자의 운송 약관상 운송이 금지된 물품인 경우

 

② 전항에 따라 서비스 제공을 거절하는 경우, 회사는 이용자에게 거절의 사유 및 근거를 통지하여야 합니다.

 

③ 이용자가 제1항 각 호에 해당하는 서비스 이용 요청을 하였음에도 불구하고 배송대행신청서에 허위 정보를 기재하여 회사가 과태료, 벌금 등의 손해를 입은 경우, 이용자는 회사의 손해를 배상하여야 합니다.

 

제5조(배송대행신청서)

① 이용자는 배송대행 계약을 체결하는 때에 다음 각 호의 사항을 기재한 배송대행신청서를 작성하여야 합니다.

1. 운송물 수령을 위한 배송대행지

2. 운송물을 구매한 해외 구매처(매장 내지 해외 전자상거래 사이트 등)에 관한 정보

3. 해당 해외 전자상거래 사이트에서 발행한 주문번호와 배송추적번호

4. 상품명, 브랜드, 해외사업자, 품목, 구매가격, 수량, 색상, 사이즈

5. 해외 세금, 해외 배송비, 할인금액

6. 재포장, 추가포장 관련 선택사항, 운송물의 개봉 및 검수에 관한 선택사항

7. 운송물의 국내 수령 장소, 이름(또는 상호) 및 연락처

8. 개인통관고유부호

9. 기타 배송대행에 필요한 사항

 

② 회사는 배송대행계약을 체결하는 때에 이용자에게 다음 각 호의 사항을 설명하여야 합니다.

1. 이용자가 배송대행신청서에 기재한 운송물의 가액이 채무불이행으로 인한 회사의 손해배상책임을 정할 때 손해배상액 산정기준이 된다는 점

2. 운송물의 가액에 따라 할증요금 또는 보험 등이 있는 경우 이에 따라 손해배상한도액에 차이가 있다는 점

3. 운송물 구매계약의 청약철회, 해지 또는 해제(이하 “청약철회 등”)는 이용자가 구매계약을 체결한 해외사업자에게 직접 해야 한다는 점

 

제6조(계약의 성립)

① 회사는 이용자의 배송대행신청이 있는 경우 이용자에게 수신확인통지를 하여야 합니다. 수신확인통지에는 배송대행신청 정보와 신청의 정정, 취소 등에 관한 정보 등을 포함하여야 합니다.

 

② 배송대행 계약은 이용자의 배송대행신청에 대하여 회사의 수신확인통지가 이용자에게 도달한 때에 성립됩니다.

 

③ 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우 지체 없이 회사에 배송대행신청 변경 또는 취소를 요청할 수 있으며, 회사는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 요금을 지급한 경우에는 제13조의 청약철회 등에 관한 규정에 따릅니다.

 

④ 배송대행 계약이 성립하고 이용자가 배송대행을 신청한 운송물이 회사의 배송대행지에 입고되면 회사는 계약에 따른 서비스의 제공을 개시합니다.

 

제7조(배송대행요금 청구와 보관료)

① 이용자는 회사의 배송대행지에 운송물이 도착하기 전까지 “몰”에 배송대행신청서를 송신해야 할 의무가 있습니다.

 

② 배송대행계약이 체결되고 해당 운송물이 회사의 배송대행지에 입고되면 회사는 이용자에게 배송대행요금의 지급을 청구하고 이용자는 지급 청구가 있은 날로부터 7일 이내에 해당 요금을 지급하여야 합니다. 추가 운송료가 발생할 시에는 회사는 이용자에게 사유와 금액을 통지해야 합니다.

 

③ 제2항에 따른 기일까지 배송대행요금이 지급되지 않을 경우 회사는 해당 운송물을 배송하지 않습니다. 이 경우 지급 요청 기간 경과 후부터 이용자가 요금을 지급한 날까지의 기간에 대하여 보관료가 추가로 부과됩니다. 회사는 추가 보관료 부과 사유 및 부과 기준을 사전에 홈페이지 등에 게시하고, 제2항에 따른 배송대행요금 지급청구 시에 이용자에게 구체적으로 명시해야 합니다.

 

제8조(검수)

① 회사는 배송대행계약이 체결되고 배송대행지에 입고된 운송물에 대하여 동일성 여부 및 하자·파손 여부, 운송물이 수출입 법령 등에 저촉되지 않는지 여부 등의 확인을 위해 해당 운송물의 개봉·검수를 할 수 있습니다. 회사는 검수의 기준 및 범위를 정하여 이용자에게 미리 통지합니다.

 

② 회사가 해당 운송물의 하자, 파손 등을 발견한 경우, 이용자에게 이를 통보하고 운송물의 처분에 대한 이용자의 지시에 따릅니다. 회사는 검수의 하자로 인해 이용자가 입은 손해를 배상해야 합니다. 다만, 회사가 제공하는 검수 범위를 벗어난 하자 및 운송물의 성능·기능상의 하자에 대하여는 손해배상책임을 지지 않습니다.

 

③ 회사가 해당 운송물의 검수를 통해 악취, 액체누수 등의 이상이 있다고 판단한 경우 이용자에게 이러한 사실을 통지하고 해당 운송물을 별도 보관하는 등 임시조치를 취할 수 있습니다. 임시 조치 과정상 이용자에게 손해가 발생한 경우 회사의 고의 또는 중과실로 인한 경우를 제외하고 해당 손해에 대하여 회사는 책임을 지지 않습니다.

 

④ 전항의 경우 회사는 이용자의 요구에 따라 해당 운송물을 처분하며, 해당 운송물에 발생한 이상이 회사의 책임 있는 사유로 인해 발생한 때에는 이용자의 손해를 배상하고 처분에 소요되는 비용을 부담합니다. 다만, 해당 운송물에 발생한 사고가 운송물의 성질이나 하자 또는 회사의 책임 없는 사유로 인한 것인 때에는 이용자가 처분에 소요되는 비용을 부담합니다.

 

제9조(재포장 및 추가포장)

① 회사는 제8조에 따라 운송물의 검수 시에 이용자의 동의를 얻어 해당 운송물을 재포장할 수 있습니다.

 

② 회사는 포장이 운송에 적합하지 아니한 때에는 이용자의 동의를 얻어 운송 중 발생될 수 있는 충격의 정도를 고려하여 추가 포장을 할 수 있습니다. 이 과정에서 추가적인 포장비용이 발생할 경우 회사는 이용자에게 추가요금을 청구할 수 있습니다.

 

③ 제1항과 2항의 경우, 회사는 이용자에게 재포장으로 인해 해외사업자에 대한 반품 및 환급 요구가 제한될 가능성이 있음을 통지하여야 합니다.

 

④ 이용자가 제2항의 추가포장에 동의하지 아니하는 경우에는 포장 부적합 또는 포장 불충분으로 인하여 발생한 손해에 대해서는 회사는 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.

 

제10조(운송현황의 게시 및 통지) 회사는 운송물의 입고, 출고, 선적 등 운송현황에 대한 정보를 몰에 게시하고 이용자에게 통지합니다. 다만, 운송물의 운송현황은 해당 시기의 물류상황, 항공운송 상황 등 제반 사정에 의해 달라질 수 있습니다.


제11조(통관)

① 회사는 수입요건을 구비하여 회사가 정한 운송 및 통관업무 위탁사를 통하여 통관절차를 수행합니다. 이 때 발생하는 관세, 부가세 등 제세금은 이용자 또는 수하인이 부담합니다. 이용자가 제세금을 납부하지 아니하면 회사는 해당 운송물을 배송하지 않습니다.

 

② 회사는 수출입국가의 관세법 등 관련법령을 준수하고, 불법운송물을 취급하지 않으며, 분할배송 및 가격허위 신고 등 이용자의 불법행위 요청에는 협조하지 않습니다.

 

③ 회사는 서비스 등이 수출입국가의 관세규정에 따라 통관이 가능한지 여부에 대해서는 보증하지 않습니다.

 

제12조(긴급조치)

① 회사는 운송물에 관하여 이용자의 책임 있는 사유로 인하여 관할관청 등의 관련법령에 근거한 적법한 인도 요구가 있는 경우, 해당 운송물을 관할관청 등에 인도합니다. 이 경우 회사는 지체 없이 이를 이용자에게 통지합니다.

 

② 제1항의 조치로 인하여 이용자가 손해를 입었다고 할지라도 해당 손해에 대하여 회사는 책임을 지지 않으며, 이 경우에도 이용자의 해당 운송물에 관한 서비스 이용 요금 및 관련 비용 등의 지급의무는 존속됩니다.

 

③ 제1항의 관할관청 등이 운송물을 반환한 경우, 회사는 지체 없이 배송대행계약에 따른 배송을 계속해서 이행해야 합니다.

 

제13조(청약철회 등)

① 회사가 제공하는 해외의 배송대행지에 이용자가 구매한 운송물이 입고되어 이용자의 국내 수령 장소로 발송되기 전에 이용자가 배송대행계약의 청약철회 등을 하는 경우, 해당 운송물은 이용자의 요청에 따라 해외의 배송대행지로부터 해외사업자에게 반송되며, 반송에 소요되는 비용은 이용자가 부담합니다.

 

② 운송물이 배송대행지로부터 출고되어 이용자의 국내 수령 장소로 발송된 이후에는, 이용자는 배송대행계약의 청약철회 등을 할 수 없으며, 회사는 해당 운송물을 이용자의 국내 수령 장소까지 배송함으로써 배송대행계약에 따른 이행을 완료합니다. 이때에 이용자는 배송대행요금의 환급을 청구하지 못합니다.

 

③ 운송물이 이용자의 국내 수령 장소까지 배송된 이후, 회사가 이용자의 반송 요청에 따라 반송을 대행할 수 있는 경우에는, 회사는 해당 운송물을 반송 할 수 있습니다. 이 때 국제 반송에 소요되는 비용은 이용자가 부담해야 합니다.

 

④ 회사는 사전에 배송대행계약의 청약철회 등이 제한되는 경우를 이용자가 쉽게 알 수 있는 곳에 표시하는 등의 조치를 하여야 하며, 이와 같은 조치를 취하지 않았다면 이용자의 청약철회 등은 제한되지 않습니다.

 

제14조(손해배상)

① 회사의 배송대행지로부터 이용자가 지정한 국내 수령 장소까지의 운송구간에서 운송물의 분실, 파손 등의 손해가 발생하였을 경우, 회사는 자기 또는 사용인 기타 운송을 위하여 사용한 자가 운송물의 수령, 인도, 보관 기타 운송에 관하여 주의를 게을리 하지 아니하였음을 증명하지 아니하면, 이용자에게 분실, 파손 등으로 인한 손해를 배상해야 합니다. 다만, 운송물의 가액에 따라 할증요금 또는 보험 등이 있는 경우, 이에 따라 손해배상한도액에 차이가 있을 수 있습니다.

 

② 운송물이 분실된 경우 이용자는 해외사업자가 사용한 해외운송인이 발급한 배송추적번호를 회사에 제공하고, 회사는 당해 배송추적번호를 이용하여 재화 등이 회사의 배송대행지에 입고되었음이 확인된 경우에 한하여 손해를 배상합니다.

 

③ 회사는 배송대행신청서에 기재된 운송물의 구매비용, 관·부가세 및 배송대행요금의 합산금액을 한도로 산정된 이용자의 실제 손해를 배상합니다. 다만 운송물의 분실, 파손 등이 회사의 고의 또는 중과실로 인한 경우에는 회사는 모든 손해를 배상하여야 합니다.

 

④ 이용자가 부정확한 정보를 기입하여 손해가 발생한 경우, 회사의 고의 또는 중과실로 인한 경우를 제외하고 배상 책임을 지지 않습니다.

 

⑤ 회사는 배송대행지를 변경할 수 있으며, 배송대행지 이전 및 이후 각각 1개월 이상의 기간을 정하여 이용자에게 그 사실을 통지하고 해당 통지기간이 경과하면, 이용자가 변경 전의 배송대행지 주소로 배송대행을 신청한 운송물의 분실 등 사고에 대하여는 책임을 지지 않습니다.

 

⑥ 회사는 제1항, 제2항, 제5항의 규정에도 불구하고 운송물의 분실, 파손 등이 회사 또는 그의 사용인의 고의 또는 중대한 과실로 인하여 발생한 때에는 손해를 배상합니다.

 

제15조(사고발생시의 운임 등의 환급과 청구)

① 회사는 천재지변 및 기타 불가항력적인 사유에 의하여 발생한 운송물의 분실, 파손 등에 대해서는 손해배상책임을 지지 않습니다.

 

② 회사는 운송물의 분실, 파손 등이 천재지변 및 기타 불가항력적인 사유로 인한 것인 때에는 배송대행요금을 청구하지 못하며, 회사가 이미 배송대행요금 및 기타 요금을 받은 때에는 이를 환급합니다.

 

제16조(책임의 특별소멸사유와 시효)

① 운송물의 누락 또는 파손에 대한 회사의 손해배상책임은 이용자가 운송물을 수령한 날로부터 7일 이내에 그 누락 또는 파손에 대한 사실을 회사에 통지하지 아니하면 소멸합니다.

 

② 회사가 제1항에 따라 손해배상책임을 부담하는 경우에도 이러한 책임은 이용자가 운송물을 수령한 날로부터 1년이 경과하면 소멸합니다. 다만, 운송물이 분실된 경우에는 이용자가 회사의 분실 사실 통지를 수령한 날로부터 기산합니다.

 

③ 제1항과 제2항의 규정에도 불구하고 회사 또는 그 사용인이 운송물의 누락 또는 파손의 사실을 알면서 이를 숨기고 운송물을 인도한 경우에 회사의 손해배상책임은 이용자 또는 배송대행신청서상 수령인이 운송물을 수령한 날로부터 5년간 존속합니다.

 

제17조(분쟁해결)

① 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.

② 회사는 이용자로부터 제출되는 불만사항 및 의견을 다른 사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 지체 없이 통지하여야 합니다.

③ 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 이용자 간에 발생한 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

 

제18조(관할법원 및 준거법)

① 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 이용자 간에 발생한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.

② 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 이용자 간에 발생한 소송의 준거법은 대한민국법으로 합니다. 



제19조(적립금)

① 회사가 정한 기준에 의해 무상으로 지급되며 현금 환불은 불가합니다.

② 회사가 정한 일정 기간이 지나면 미사용 적립금은 소멸될 수 있으며 소멸된 적립금은 복구가 불가합니다.
      </textarea>
    </div>
  </div>
  <div v-else-if="policyIndex === 500">
    <div class="div_title">
      <h1>개인정보 취급방침</h1>
      <policies/>
    </div>
    <div>
      <textarea class="" style="width:100%;height:1000px;">
GEEK9는 (이하 "회사"로 표기)은 고객의 개인정보를 보호하기 위해 노력하고 있으며 통신비밀보호법, 정보통신망 이용촉진 등의 법률을 준수하고 있습니다.
회사는 개인정보취급방침을 통해 고객이 제공하는 개인정보의 용도와 개인정보보호 조치에 대해 알려드립니다.

본 방침은 2021년 9월 9일부터 시행됩니다.

1. 수집하는 개인정보의 항목 및 수집방법
2. 개인정보의 수집 및 이용목적
3. 개인정보의 보유기간, 제공
4. 개인정보 파기절차 및 방법
5. 이용자 및 법정대리인의 권리와 그 행사방법
6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
7. 개인정보에 관한 민원 서비스

1. 수집하는 개인정보의 항목 및 수집방법
회사는 회원가입, 상담, 서비스 제공 등을 위해 다음과 같은 개인정보를 수집하고 있습니다.

(1) 수집항목
필수입력항목: 닉네임, ID, 비밀번호, 이메일주소
선택입력항목: 사진, 성별, 생일, 홈페이지주소, 자기소개
비입력항목: 브라우저 종류, 운영체제, 검색어, 서비스 이용 및 방문 기록, IP주소, 쿠키

(2) 수집방법
홈페이지, 게시판, 이메일, 이벤트 응모, 전화
비입력항목 수집 도구를 이용한 수집

2. 개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.

(1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
- 콘텐츠 제공, 물품배송 또는 청구서 등 발송, 구매 및 요금 결제, 금융서비스 및 본인 인증

(2) 회원관리
- 회원제 서비스 이용 및 개인 식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 연령 확인, 민원 처리, 고지사항 전달

(3) 마케팅 및 광고, 통계 활용
- 새 서비스 개발 및 서비스 제공, 광고 게재, 이벤트, 광고 제공, 정보 제공, 회원의 서비스 이용 통계 작성

3. 개인정보의 보유기간, 제공
(1) 개인정보 제공
회사는 "2. 개인정보의 수집목적 및 이용목적"에서 알린 범위 내에서 사용하며 이용자의 사전 동의 없이는 범위를 초과하여 사용하거나 외부에 공개, 제공하지 않습니다. 단 다음과 같은 경우에는 예외로 합니다.
- 이용자의 동의를 구한 경우
- 적접한 절차에 의한 정부 수사기관의 요청과 같이 법에 의해 필요하다고 판단되는 경우

(2) 보유기간
개인정보는 서비스를 이용하는 동안 원활한 서비스 이용을 위해 계속 보유하나 회원 탈퇴 신청이나 서비스 중단 등의 상황이 발생할 경우 해당 정보를 바로 파기하거나 외부 공개를 막습니다. 단 다음의 경우에는 명시한 기간 동안 보존합니다.

* 보존항목: ID, 생년월일, 이메일주소
* 보존이유: 서비스 이용의 혼선 방지, 불법적 사용자에 대한 관련 기관 수사협조
* 보존기간: 1년

또한 관계법령에 정한 기간 동안 회원정보를 보관할 수 있습니다.

* 보존항목: 서비스 이용기록, 방문 기록, IP 정보
* 보존이유: 통신비밀보호법
* 보존기간: 3개월

4. 개인정보 파기절차 및 방법
회사는 원칙적으로 앞서 말한 '3-(2) 보유기간'에 해당하지 않은 경우 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
- 회원의 탈퇴 또는 서비스 중지가 일어날 경우 해당 정보를 파기합니다.
- 종이에 출력된 정보는 분쇄나 소각의 형태로 파기합니다.
- 전자적 파일이나 코드 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

5. 이용자 및 법정대리인의 권리와 그 행사방법
이용자 및 법정 대리인은 언제든지 자신의 정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다. 혹은 개인정보관리책임자에게 서면, 전화, 이메일로 연락하시면 바로 처리해드립니다.

6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
회사는 서비스 제공을 위해 '쿠키(cookie)'를 사용합니다. 쿠키는 서버가 이용자의 컴퓨터 브라우저에게 보내는 작은 정보로 이용자의 단말기 내 저장장치에 저장됩니다. 회사는 다음의 목적을 위해 쿠키를 사용합니다.

(1) 쿠키의 사용 목적
서비스 이용자나 방문자의 접속 상태 및 기록을 파악하여 좀더 최적화된형 서비스 제공이나 통계 작성에 사용합니다.

(2) 쿠키의 거부
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 옵션을 설정함으로써 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 쿠키의 저장을 거부할 수 있습니다. 단 쿠키의 저장을 거부할 경우에는 서비스 이용에 불편이 발생할 수 있습니다.

- 설정방법 보기(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 '도구' 선택 > 인터넷 옵션 > 개인정보 순으로 선택

7. 개인정보에 관한 민원 서비스
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 민원을 처리하기 위하여 아래와 같이 개인정보관리책임자를 지정해 개인정보를 관리하고 있습니다. 이용자는 개인정보 관련 민원을 관리자에게 신고할 수 있으며, 회사는 빠르게 조치를 취하거나 답변을 드립니다.

* 개인정보관리책임자 성명:
* 전화번호: 010-8235-0642
* 이메일: withpd@gmail.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

1. 개인분쟁조정위원회 (1336)
2. 정보보호마크인증위원회 (02-580-0533~4)
3. 대검찰청 인터넷범죄수사센터 (02-3480-3600)
4. 경찰청 사이버테러대응센터 (02-392-0330)

사이트에 링크되어 있는 웹사이트에서 개인정보를 수집하는 행위에 대해서는 본 "사이트 개인정보취급방침"이 적용되지 않음을 알려 드립니다.
      </textarea>
    </div>
  </div>
  <div v-else >
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div class="card no-result">
      <p>개인 해외직구/구매대행/배송대행/결제대행 관련 GEEK9의 정책에 대해 조회하실 수 있습니다.</p> 
      <p>정책에 명시된 내용에 대해서는 어떠한 경우에도 예외를 두지 않습니다.</p>
      <p>정책 미숙지로 인한 책임은 모두 구매자님께 있습니다.</p>
    </div>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import Policies from '../common/policies.vue'
import ssr from '../common/ssr'
import context from '@/components/common/Context.vue'

export default {
  emits: ["setCookies", "setMenu"],
  components: {
    Policies, context
  },  
  mounted() {
    this.addJsonLd()
    this.api.setPage('policy')
    this.$emit("setMenu", {page:'policy'})
    console.log(this.api.getCookies('page'))
  },
  setup() {
    const cate = ref([
      { label: '게시판', route: 'https://contents.geek9.kr' },
      { label: '정책', route: '/policy/-1' }
    ])
    const setMeta = inject('setMeta')
    const route = useRoute()
    const policyIndex = Number(route.params.index)
    const title = 'GEEK9 > 정책'
    const keywords = '규정,내규,정책,GEEK9,policy,분쟁,배송,사기,주문,대행,비용,수수료,견적'
    const description = '해외직구 구매대행/배송대행/결제대행에 대한 GEEK9 정책입니다.'
    const geek9Url = ssr.GEEK9_URL + 'policy/' + policyIndex
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    // useMeta( {
    //   title: 'GEEK9 > 정책',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/policy/-1'}],
    //   meta: [
    //     { vmid: 'charset_policy', charset: 'utf-8' },
    //     { vmid: 'http-equiv_policy', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_policy', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_policy', name: 'keywords', content:'규정,내규,정책,GEEK9,policy,분쟁,배송,사기,주문,대행,비용,수수료,견적' },
    //     { vmid: 'viewport_policy', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_policy', name: 'description', content: '해외직구 구매대행/배송대행/결제대행에 대한 GEEK9 정책입니다.' },
    //     { vmid: 'theme-color_policy', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_policy', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_policy', property: 'og:description', content: '해외직구 구매대행/배송대행/결제대행에 대한 GEEK9 정책입니다.'},
    //     { vmid: 'og:title_policy', property: 'og:title', content: 'GEEK9 > 정책'},
    //     { vmid: 'og:image_policy', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_policy', property: 'og:url', content: 'https://geek9.kr/policy/-1'}
    //   ]
    // })
    const addJsonLd = () => {
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          el.remove()
        }
      })
      const script = document.createElement('script')
      script.setAttribute('type', 'application/ld+json')
      script.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
          {
            "@type": "BreadcrumbList",
            "name": "GEEK9 개인해외직구 정책",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "주문정책",
              "item": "https://geek9.kr/policy/0",
              "description": "해외 상품의 구매와 해외직구에 필요한 서비스에 대한 GEEK9 정책입니다."
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "비용정책",
              "item": "https://geek9.kr/policy/1",
              "description": "수수료/세금 등 대행을 맡기셨을 때 발생에 대한 GEEK9 정책입니다."
            },{
              "@type": "ListItem",
              "position": 3,
              "name": "배송정책",
              "item": "https://geek9.kr/policy/2",
              "description": "국내외 배송에 대한 GEEK9 정책입니다."
            },{
              "@type": "ListItem",
              "position": 4,
              "name": "분쟁정책",
              "item": "https://geek9.kr/policy/3",
              "description": "해외직구 과정에서 발생하는 이슈에 대한 GEEK9 정책입니다."
            },{
              "@type": "ListItem",
              "position": 5,
              "name": "기타정책",
              "item": "https://geek9.kr/policy/4",
              "description": "해외직구 과정에서 자주 발생하는 다양한 상황에 대한 GEEK9 정책입니다."
            }]
          }
        ]
        
      })
      document.head.appendChild(script)
    }

    return {
      policyIndex, addJsonLd, cate
    }
  },
}
</script>