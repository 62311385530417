<template>
   <Breadcrumb :home="home" :model="context">
    <template #item="{ item, props }">
      <router-link v-if="item.route !== undefined && item.route.indexOf('https://') === -1" v-slot="{ href, navigate }" :to="item.route" custom>
        <a :href="href" v-bind="props.action" @click="navigate">
          <span :class="[item.icon, 'text-color']" />
          <span class="text-primary font-semibold">{{ item.label }}</span>
        </a>
      </router-link>
      <a v-else :href="item.route" :target="item.target" v-bind="props.action">
        <span class="text-surface-700 dark:text-surface-0">{{ item.label }}</span>
      </a>
    </template>
  </Breadcrumb>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    context: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const home = ref({
      icon: 'pi pi-home',
      route: '/'
    })
    return {
      home
    }
  }
}
</script>
