<template>
  <Select v-model="policy" :options="policies" optionLabel="name" placeholder="GEEK9 정책" @change="change" />
</template>

<script>
import { ref } from 'vue'
import router from '../../router'

export default {
  methods: {
    change() {
      const routeData = router.resolve({
        name: 'policy',
        params: { index: this.policy.code }
      })
      window.open(routeData.href, '_blank')
      this.policy = {}
    }
  },
  components: {
  },
  setup() {
    const policy = ref({})
    const policies = ref([
        { name: '주문정책', code: 0 },
        { name: '비용정책', code: 1 },
        { name: '배송정책', code: 2 },
        { name: '분쟁정책', code: 3 },
        { name: '이용약관', code: 400 },
        { name: '개인정보', code: 500 },
        { name: '기타', code: 4 },
    ])
    return {
      policy, policies
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
</style>
