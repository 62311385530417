const GEEK9_URL = 'https://geek9.kr/'

const getWebsite = (url, name, description) => {
  return {
    "@type": "WebSite",
    "@id": `${url}#webSite`,
    "url": `${url}`,
    "name": name,
    "description": description,
    "publisher": {"@id": "https://geek9.kr/#organization"}
  }
}

const getOrganization = (url, name) => {
  return {
    "@type": "Person",
    "@id": `${url}#organization`,
    "name": name,
    "url": url,
    // "logo": {
    //   "@type": "ImageObject",
    //   "url": "https://geek9.kr/img/logo_big.e0186dc8.jpg",
    //   "width": 300,
    //   "height": 300
    // },
    "sameAs": [
      "https://geek9.kr/biz",
      "https://kmong.com/gig/557598",
      "https://cafe.naver.com/geek9",
      "https://www.instagram.com/geek9geek9/",
      "https://www.youtube.com/@sosoceo/videos",
      "https://blog.naver.com/sosoceo"
    ]
  }
}

const getWebPage = (url, name, partUrl, description) => {
  return {
    "@type": "WebPage",
    "@id": `${url}#webPage`,
    "url": url,
    "name": name,
    "about": {"@id": "https://geek9.kr/#organization"},
    "description": description,
    "publisher": {"@id": "https://geek9.kr/#organization"}, 
    "isPartOf": {"@id": `${partUrl}#webSite`}
  }
}

export default {
  getWebsite, getOrganization, getWebPage, GEEK9_URL
}